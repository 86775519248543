import { Route, Routes } from "react-router-dom";

import "./App.css";
import MainDash from "./components/MainDash/MainDash";
import Sidebar from "./components/Sidebar";
import Permissions from "./pages/Permissions/Permissions";
import UpdatePermissions from "./pages/Permissions/update/UpdatePermissions";
import Roles from "./pages/Roles/Roles";
import UpdateRole from "./pages/Roles/Update/UpdateRole";
import Services from "./pages/Services/Services";
import UpdateServices from "./pages/Services/Update/UpdateServices";
import Sessions from "./pages/Sessions/Sesssions";
import Users from "./pages/Users/Users";
import UpdateUser from "./pages/Users/Update/UpdateUser";
import Layout from "./layout/Layout";
import Error404 from "./pages/Error404/Error404";
import SubServices from "./pages/SubServices/SubServices";
import UpdateSubService from "./pages/SubServices/Update/UpdateSubService";
function App() {
  return (
    <div className="dashboard-App">
      <div className="AppGlass">
        <Sidebar />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/dashboard" element={<MainDash />} />
            <Route path="/permissions" element={<Permissions />} />
            <Route path="/permissions/:id" element={<UpdatePermissions />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/roles/:id" element={<UpdateRole />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/:id" element={<UpdateServices />} />
            <Route path="/sub-services" element={<SubServices />} />
            <Route path="/sub-services/:id" element={<UpdateSubService />} />
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<UpdateUser />} />
          </Route>
          {/* // !Catch All */}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
