//css
import "./App.css";
//router
import { Routes, Route } from "react-router-dom";
//pages
import Layout from "./layout/Layout";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Service from "./components/Service/Service";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Dashboard from "./dashboard/App";
import Login from "./dashboard/pages/Login/Login";
import Unauthorized from "./dashboard/pages/Unauthorized/Unauthorized";

//components
import CustomCursor from "./components/CustomCursor/CustomCursor";
import Error from "./pages/Error/Error";
import RequireAuth from "./dashboard/components/RequireAuth/RequireAuth"; //!Never Forget To UnComment when Api is ready
function App() {
  return (
    <div className="App">
      <CustomCursor />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="services/:id" element={<Service />} />

          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Route>
        {/* // !Public Routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* // ! Route To Protect */}
        <Route element={<RequireAuth />}>
          <Route path="/dashboard/*" element={<Dashboard />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
