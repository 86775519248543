import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
//css
import "./Footer.css";
//icons
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
//bootstrap
import { Col, Container, Row } from "react-bootstrap";
//====================images===================
import FooterLogo from "../../assets/Img/footerLogo.png";
//translation
import { t } from "i18next";
//api
import axios from "../../api/axios";
const Footer = () => {
  const [services, setServices] = useState([]);
  //=================Get All Services=======================

  useEffect(() => {
    const getServices = async () => {
      var config = {
        method: "get",
        url: "https://www.api.attargroup-co.com/service/",
      };

      await axios(config)
        .then(function (response) {
          setServices(response?.data?.rows);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getServices();
  }, []);
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={5} lg={4}>
            <div className="footer-s1">
              <img src={FooterLogo} alt="al-attar-group" />
              <p>{t("Components.Footer.logoDesc")}</p>
              <ul className="footer-s1-ul">
                <li>
                  <a href="#" alt="facebook">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="#" alt="twitter">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="" alt="instagram">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="" alt="youtube">
                    <AiOutlineYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className="footer-s2">
              <p className="p-title">{t("Components.Footer.lists.general")}</p>
              <ul>
                <li>
                  <NavLink to={"/"}>
                    {t("Components.Footer.footerGeneralLinks.home")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/services"}>
                    {t("Components.Footer.footerGeneralLinks.services")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/about"}>
                    {t("Components.Footer.footerGeneralLinks.about")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/contact"}>
                    {t("Components.Footer.footerGeneralLinks.contact")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className="footer-s3">
              <p className="p-title">{t("Components.Footer.lists.services")}</p>
              <ul>
                {services.length > 0 &&
                  services
                    .slice(0, 4)
                    .map((service, index) => (
                      <li key={index}>{service.title_en}</li>
                    ))}
              </ul>
            </div>
          </Col>
          <Col>
            <div className="footer-s4">
              <p className="p-title">{t("Components.Footer.lists.contact")}</p>
              <ul>
                <li>
                  <div className="footer-s4-phones">
                    <span dir="ltr">+972 79 555 1502</span>
                    <span dir="ltr">+962 64 393 643</span>
                  </div>
                </li>
                <li>
                  <a href="mailto:muhannadattar@hubjo.co">
                    muhannadattar@hubjo.co
                  </a>
                </li>
                <li>
                  <address>Amman, Jordan</address>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
