export const languages = [
  {
    code: "en",
    name: "English",
    dir: "ltr",
    country_code: "gb",
  },

  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];
