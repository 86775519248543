import { t } from "i18next";
import React from "react";
//css
import "./HappyClients.css";
const HappyClients = ({ imagesArr, numberOfCustomers }) => {
  return (
    <div className="happy-clients">
      <div className="happy-clients-images">
        {imagesArr.map((img, index) => (
          <img key={index} src={img} alt="img" className="happy-client-img" />
        ))}
      </div>
      <p>
        <span className="p-title text-white">{numberOfCustomers}</span> +{" "}
        {t("Components.Banner.happyClients")}
      </p>
    </div>
  );
};

export default HappyClients;
