import React from "react";
import { Button, Modal } from "react-bootstrap";
//images
import SUCCESSIMG from "../../../assets/Img/sucess.png";
import FAILURE from "../../../assets/Img/failure.png";
const PopUp = ({ modalShow, setModalShow, success }) => {
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {success === true ? <h3>Success</h3> : <h3>Failure !!!!</h3>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center d-flex flex-column align-items-center justify-content-center">
            {success === true ? <h4>Success</h4> : <h4>Failure !!!!</h4>}

            {success === true ? (
              <img src={SUCCESSIMG} alt="SUCCESSIMG" />
            ) : (
              <img src={FAILURE} alt="SUCCESSIMG" />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant="dark">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default PopUp;
