import React from "react";
//css
import "./About.css";
//components
import Banner from "../../components/Banner/Banner";
//images
import BannerIMG from "../../assets/Img/Banner.png";
import Service3 from "../../assets/Img/Service3.png";
import GRAPHICIMG from "../../assets/Img/graphic_elment03.png";
import GRAPHICIMG2 from "../../assets/Img/graphic_elment02.png";
//bootstrap
import { Container } from "react-bootstrap";
//translation
import { t } from "i18next";
const About = () => {
  return (
    <section className="about-us">
      <Banner
        h_text={t("Pages.About.bannerTitle")}
        p_text={t("Pages.About.bannerDesc")}
        buttonClass={"secondary-btn"}
        backgroundImg={BannerIMG}
      />
      <div className="about-us-sections">
        <div className="about-us-s1">
          <Container>
            <div className="about-us-s1-data">
              <div className="about-us-s1-img">
                <img src={Service3} alt="serviceImg" />
              </div>
              <div className="about-us-s1-info">
                <h2>{t("Pages.About.Section1.title")}</h2>
                <p>{t("Pages.About.Section1.Part1")}</p>
                <p>{t("Pages.About.Section1.Part2")}</p>
              </div>
            </div>
          </Container>
          <img src={GRAPHICIMG} alt="GRAPHICIMG" id="about-us-s1-GRAPHICIMG1" />
          <img src={GRAPHICIMG} alt="GRAPHICIMG" id="about-us-s1-GRAPHICIMG2" />
        </div>
        <div className="about-us-s2">
          <Container>
            <div className="about-us-s2-data">
              <div className="about-us-s2-img-container">
                <div className="about-us-s2-img">
                  <h2>{t("Pages.About.Section2.title")}</h2>
                  <p>{t("Pages.About.Section2.Part1")}</p>
                  <p>{t("Pages.About.Section2.Part2")}</p>
                </div>
              </div>
            </div>
          </Container>
          <img
            src={GRAPHICIMG2}
            alt="GRAPHICIMG2"
            id="about-us-s2-GRAPHICIMG"
          />
        </div>
        <div className="about-us-s3">
          <Container>
            <div className="about-us-s3-data">
              <div className="about-us-s3-img">
                <img src={Service3} alt="serviceImg" />
              </div>
              <div className="about-us-s3-info">
                <img
                  src={GRAPHICIMG}
                  alt="GRAPHICIMG"
                  id="about-us-s3-GRAPHICIMG1"
                />
                <h2>{t("Pages.About.Section3.title")}</h2>
                <p>{t("Pages.About.Section3.Part1")}</p>
                <p>{t("Pages.About.Section3.Part2")}</p>
              </div>
            </div>
            <img
              src={GRAPHICIMG}
              alt="GRAPHICIMG"
              id="about-us-s3-GRAPHICIMG2"
            />
          </Container>
        </div>
      </div>
    </section>
  );
};

export default About;
