import React from "react";
//css
import "./Contact.css";
//components
import ContactCard from "../../components/ContactCard/ContactCard";
const Contact = () => {
  return (
    <div className="contact-us">
      <div className="contact-us-map background-set">
        <ContactCard />
      </div>
    </div>
  );
};

export default Contact;
