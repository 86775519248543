import React from "react";
import { useLocation } from "react-router-dom";
//images
import PersonImg1 from "../../assets/Img/person5.jpg";
import PersonImg2 from "../../assets/Img/person2.jpg";
import PersonImg3 from "../../assets/Img/person3.jpg";
import PersonImg4 from "../../assets/Img/person4.jpg";
//css
import "./Banner.css";
//bootstrap
import { Row, Col, Container } from "react-bootstrap";
//icons
import { AiOutlineArrowDown } from "react-icons/ai";
//components
import Button from "../Button/Button";
import HappyClients from "../HappyClients/HappyClients";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";
const Banner = ({ h_text, p_text, buttonClass, backgroundImg }) => {
  const location = useLocation();
  const PersonsImages = [PersonImg1, PersonImg2, PersonImg3, PersonImg4];
  const isMobile = useMediaQuery({ maxWidth: 575 });
  return (
    <div className="banner">
      <div
        className="banner-bg-img"
        style={{
          background: isMobile
            ? "none"
            : `linear-gradient(90deg, #191814e5 55%, #00ffff00 0%), url(${backgroundImg})`,
        }}
      >
        <div
          className="banner-bg-img-blur"
          style={{
            background: isMobile
              ? "none"
              : `linear-gradient(90deg, #191814e5 100%, #00ffff00 0%), url(${backgroundImg})`,
          }}
        ></div>
      </div>
      <Container className="h-100">
        <Row className="h-100">
          <Col sm md>
            <div className="banner-s1">
              <h1>
                <span>{h_text}</span>
              </h1>
              <p>{p_text}</p>
              <Button
                classNames={buttonClass}
                text={t("Components.Banner.btn")}
                icon={<AiOutlineArrowDown />}
              />
              {location.pathname === "/" && (
                <div className="happy-clients-container">
                  <HappyClients
                    imagesArr={PersonsImages}
                    numberOfCustomers={1252}
                  />
                  <HappyClients
                    imagesArr={PersonsImages}
                    numberOfCustomers={1252}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm md>
            <div className="banner-s2">
              <div
                className="banner-bg-img-s2"
                style={{
                  background: isMobile ? ` url(${backgroundImg})` : "none",
                  height: "100%",
                }}
              ></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
