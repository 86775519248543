import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
//components
import PopUp from "../../../components/PopUp/PopUp";
//context
import AuthContext from "../../../context/AuthProvider";
//css
import "./UpdateSubService.css";
const UpdateSubService = () => {
  const { auth } = useContext(AuthContext);
  const params = useParams();
  const subServiceId = params.id;
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [services, setServices] = useState([]);
  const [subServiceInputs, setSubServiceInputs] = useState({
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
    service_id: "",
  });
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  const handleSubServiceInputs = (e) => {
    const { name, value } = e.target;
    setSubServiceInputs({
      ...subServiceInputs,
      [name]: value,
    });
  };

  //=================Get All Services=============================
  useEffect(() => {
    const getAllServices = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/service/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
        setServices([]);
      } else {
        setServices(body.rows);
      }
    };
    getAllServices();
  }, []);
  //=================Get Sub Service By Id=========================
  const getSubServiceById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.attargroup-co.com/subservice/${subServiceId}`,
      requestOptions
    );
    const body = await response.json();
    console.log(body);
    if (!response.ok) {
      setSubServiceInputs({});
      console.log(body);
    } else {
      setSubServiceInputs(body);
    }
  }, [subServiceId]);
  useEffect(() => {
    getSubServiceById();
  }, [getSubServiceById]);
  //=================Update Sub-Service Image======================
  const UpdateSubServiceImg = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    var formdata = new FormData();
    formdata.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.attargroup-co.com/subservice/image/${subServiceId}`,
      requestOptions
    );
    if (!response.ok) {
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      alert("Image Updated");
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      navigate("/dashboard/sub-services");
    }
  };
  //=================Update Sub-Service============================
  const handleSubmit = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      title_en: subServiceInputs.title_en,
      title_ar: subServiceInputs.title_ar,
      description_en: subServiceInputs.description_en,
      description_ar: subServiceInputs.description_ar,
      service_id: subServiceInputs.service_id,
    };

    Object.keys(raw).forEach((key) => {
      if (
        raw[key] === "" ||
        raw[key] === "\n" ||
        raw[key] === undefined ||
        raw[key] === null
      ) {
        delete raw[key];
      }
    });
    let raw2 = JSON.stringify(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/subservice/${subServiceId}`,
      requestOptions
    );
    if (!response.ok) {
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      navigate("/dashboard/sub-services");
    }
  };
  return (
    <section className="update-sub-service">
      <Container>
        <Row>
          <div className="title">
            <h2>Sub - Services</h2>
            <p>Here Were You Can Update Sub Service</p>
          </div>
        </Row>
        <Row>
          <div className="dashboard-services-form">
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Choose Service Parent</Form.Label>
                <Form.Select
                  name="service_id"
                  onChange={(e) => handleSubServiceInputs(e)}
                >
                  <option>Choose Service Parent</option>
                  {services.length > 0 &&
                    services?.map((service, index) => {
                      return (
                        <option key={index} value={service.id}>
                          {service.title_en}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group className="mb-3">
                <Form.Label>Title In English</Form.Label>
                <Form.Control
                  type="text"
                  name="title_en"
                  placeholder="Enter Title In English"
                  onChange={(e) => handleSubServiceInputs(e)}
                  value={subServiceInputs.title_en}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Title In Arabic</Form.Label>
                <Form.Control
                  type="text"
                  name="title_ar"
                  placeholder="Enter Title In Arabic"
                  required
                  onChange={(e) => handleSubServiceInputs(e)}
                  value={subServiceInputs.title_ar}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description In English</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description_en"
                  rows={3}
                  placeholder="Description In English"
                  onChange={(e) => handleSubServiceInputs(e)}
                  value={subServiceInputs.description_en}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description In Arabic</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description_ar"
                  rows={3}
                  placeholder="Description In Arabic"
                  onChange={(e) => handleSubServiceInputs(e)}
                  value={subServiceInputs.description_ar}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Upload Sub - Service Image</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={handleImageChange}
                />
              </Form.Group>

              <Button variant="success" type="submit">
                Update Sub - Service
              </Button>
              <br />
              <hr />
              {Imgfile.Imgfile && (
                <Button
                  variant="info"
                  type="button"
                  onClick={UpdateSubServiceImg}
                >
                  Update Image
                </Button>
              )}
            </Form>
          </div>
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
      </Container>
    </section>
  );
};

export default UpdateSubService;
