import React, { useCallback, useEffect, useRef } from "react";

const CustomCursor = () => {
  const delay = 18;
  const dot = useRef(null);
  const dotOutline = useRef(null);
  const cursorVisible = useRef(true);
  const cursorEnlarged = useRef(false);
  const endX = useRef(window.innerWidth / 2);
  const endY = useRef(window.innerHeight / 2);
  const _x = useRef(0);
  const _y = useRef(0);
  const requestRef = useRef(null);

  const mouseOverEvent = useCallback(() => {
    cursorEnlarged.current = true;
    toggleCursorSize();
  }, []);

  const mouseOutEvent = useCallback(() => {
    cursorEnlarged.current = false;
    toggleCursorSize();
  }, []);

  const mouseEnterEvent = useCallback(() => {
    cursorVisible.current = true;
  }, []);

  const mouseLeaveEvent = useCallback(() => {
    cursorVisible.current = false;
  }, []);

  const mouseMoveEvent = useCallback((e) => {
    cursorVisible.current = true;
    endX.current = e.pageX;
    endY.current = e.pageY;
    dot.current.style.top = endY.current + "px";
    dot.current.style.left = endX.current + "px";
    dot.current.style.zIndex = 99;
  }, []);

  const animateDotOutline = useCallback(() => {
    _x.current += (endX.current - _x.current) / delay;
    _y.current += (endY.current - _y.current) / delay;
    dotOutline.current.style.top = _y.current + "px";
    dotOutline.current.style.left = _x.current + "px";
    dotOutline.current.style.zIndex = 99;
    requestRef.current = requestAnimationFrame(animateDotOutline);
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", mouseOverEvent);
    document.addEventListener("mouseup", mouseOutEvent);
    document.addEventListener("mousemove", mouseMoveEvent);
    document.addEventListener("mouseenter", mouseEnterEvent);
    document.addEventListener("mouseleave", mouseLeaveEvent);
    animateDotOutline();

    return () => {
      document.removeEventListener("mousedown", mouseOverEvent);
      document.removeEventListener("mouseup", mouseOutEvent);
      document.removeEventListener("mousemove", mouseMoveEvent);
      document.removeEventListener("mouseenter", mouseEnterEvent);
      document.removeEventListener("mouseleave", mouseLeaveEvent);
      cancelAnimationFrame(requestRef.current);
    };
  }, [
    animateDotOutline,
    mouseEnterEvent,
    mouseLeaveEvent,
    mouseMoveEvent,
    mouseOutEvent,
    mouseOverEvent,
  ]);

  const toggleCursorSize = () => {
    if (cursorEnlarged.current) {
      dot.current.style.transform = "translate(-50%, -50%) scale(0.75)";
      dotOutline.current.style.transform = "translate(-50%, -50%) scale(1.5)";
    } else {
      dot.current.style.transform = "translate(-50%, -50%) scale(1)";
      dotOutline.current.style.transform = "translate(-50%, -50%) scale(1)";
    }
  };

  return (
    <>
      <div ref={dotOutline} className="cursor-dot-outline"></div>
      <div ref={dot} className="cursor-dot"></div>
    </>
  );
};

export default CustomCursor;
