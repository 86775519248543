import React, { useContext, useEffect, useState } from "react";
//bootstrap
import { Button, Container, Row, Form, Modal } from "react-bootstrap";
//css
import "./Roles.css";
//Data Table
import DataTable from "react-data-table-component";
//components
import PopUp from "../../components/PopUp/PopUp";
//context
import AuthContext from "../../context/AuthProvider";
import { customStyles } from "../../components/DataTableCustomeStyles";
import { Link } from "react-router-dom";
//api
// import axios from "../../components/api/axios";
const Roles = () => {
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const { auth } = useContext(AuthContext);
  const [rolesArr, setRolesArr] = useState([]);
  const [role, setRole] = useState({
    roleId: "",
    roleName: "",
    roleDesc: "",
  });
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //=======delete modal State====
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  //===========================

  const handleRole = (e) => {
    const { name, value } = e.target;
    setRole({ ...role, [name]: value });
  };
  //==============================Get Role===============================
  useEffect(() => {
    const fetchRoles = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/role/",
        requestOptions
      );
      const data = await response.json();
      console.log(data);
      if (!response.ok) {
        alert(response.statusText);
        console.log(response);
      } else {
        setRolesArr(data.rows);
      }
    };
    fetchRoles();
  }, [auth.token]);
  //==============================Post Role==============================
  const handleSubmit = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      role_name: role.roleName,
      role_desc: role.roleDesc,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.attargroup-co.com/role/",
      requestOptions
    );
    if (!response.ok) {
      alert(response.statusText);
      console.log(response);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(4000);
      window.location.reload();
    }
  };
  //==============================Delete Role==============================
  const DeleteRole = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/role/${role.roleId}`,
      requestOptions
    );
    if (!response.ok) {
      alert(response.statusText);
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(4000);
      window.location.reload();
    }
  };
  //==============================Roles Table==============================

  const columns = [
    {
      name: "ID",
      selector: (row, index) => <div>{index}</div>,
    },
    {
      name: "Role Name",
      selector: (row) => <div>{row.role_name}</div>,
    },
    {
      name: "Role Desc",
      selector: (row) => <div>{row.role_desc}</div>,
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/roles/${row.id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row, index) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setRole({ ...role, roleId: row.id });
            }}
          >
            Delete Role
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure You Want To Delete This Role !! ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={DeleteRole}>
                Delete Role
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  return (
    <section className="dashboard-roles">
      <Container>
        <Row>
          <div className="title">
            <h1>Roles</h1>
            <p>Here Were You Can Manipulate Roles</p>
          </div>
        </Row>
        <Row>
          <div className="dashboard-roles">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  name="roleName"
                  placeholder="Enter Role Name"
                  required
                  onChange={(e) => handleRole(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Role Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="roleDesc"
                  rows={3}
                  resize="false"
                  placeholder="Description In Arabic"
                  required
                  onChange={(e) => handleRole(e)}
                />
              </Form.Group>

              <Button variant="success" type="submit">
                Create Role
              </Button>
            </Form>
            <PopUp
              modalShow={modalShow}
              setModalShow={setModalShow}
              success={success}
            />
          </div>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <DataTable
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderWrap
            columns={columns}
            data={rolesArr}
            customStyles={customStyles}
          />
        </Row>
      </Container>
    </section>
  );
};

export default Roles;
