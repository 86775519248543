import React, { useContext, useState } from "react";
import "./Sidebar.css";
import Logo from "../../assets/Img/LOGO.png";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { SidebarData } from "../Data/Data";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
const Sidebar = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [selected, setSelected] = useState(0);

  const [expanded, setExpaned] = useState(true);

  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-60%",
    },
  };
  const logout = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.attargroup-co.com/user/logout",
      requestOptions
    );
    if (!response.ok) {
      alert("Logout Failed");
      console.log(response);
    } else {
      setAuth({});
      localStorage.clear();
      navigate("/login");
    }
  };

  return (
    <>
      <div
        className="bars"
        style={expanded ? { left: "60%" } : { left: "5%" }}
        onClick={() => setExpaned(!expanded)}
      >
        <UilBars />
      </div>
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        {/* logo */}
        <div className="logo">
          <img src={Logo} alt="logo" />
          <span>Al Attar Group</span>
        </div>

        <div className="menu">
          {SidebarData.map((item, index) => {
            return (
              <div
                className={selected === index ? "menuItem active" : "menuItem"}
                key={index}
                onClick={() => setSelected(index)}
              >
                <Link to={item.route}>
                  <item.icon />
                  <span>{item.heading}</span>
                </Link>
              </div>
            );
          })}
          {/* signoutIcon */}
          <div className="menuItem">
            <UilSignOutAlt onClick={logout} />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
