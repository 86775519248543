import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//css
import "./NavbarComponent.css";
//bootstrap
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";
//images
import LOGO from "../../assets/Img/LOGO.png";
//translation
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { languages } from "../../languages";
import i18next from "i18next";
import classNames from "classnames";

const NavbarComponent = () => {
  const [change, setChange] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      if (newWidth >= 768) {
        setChange(true);
      }
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  //===========================Start Translation===========================
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguage.dir;
    document.querySelector("html").dir = currentLanguage.dir;
    document.querySelector("html").lang = currentLanguage.code;
  }, [currentLanguage, t]);

  return (
    <Navbar
      expand="lg"
      fixed="top"
      style={{
        height: "100px",
        background:
          location.pathname === "/contact" || "/*" ? "#191814" : "transparant",
        direction: "ltr",
      }}
    >
      <Container>
        <Navbar.Brand href="/">
          <img src={LOGO} alt="Al-Attar-Group" width={88} height={46} />
        </Navbar.Brand>
        <Navbar.Toggle
          as={"button"}
          aria-controls="basic-navbar-nav"
        ></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className="nav-link-active" href="/">
              {t("Components.Navbar.home")}
            </Nav.Link>
            <Nav.Link href="/services">
              {t("Components.Navbar.services")}
            </Nav.Link>
            <Nav.Link href="/about">{t("Components.Navbar.about")}</Nav.Link>
            <Nav.Link href="/contact">
              {t("Components.Navbar.contact")}
            </Nav.Link>
          </Nav>
          <NavDropdown
            title={t(`Components.Languages`)}
            id="basic-nav-dropdown-langs"
            className="lang-drop"
          >
            {languages.map(({ code, name, country_code }) => (
              <li key={country_code}>
                <Nav.Link
                  className={classNames("dropdown-item", {
                    disabled: currentLanguageCode === code,
                  })}
                  onClick={() => {
                    i18next.changeLanguage(code);
                    window.location.reload();
                  }}
                >
                  {name}
                </Nav.Link>
              </li>
            ))}
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
