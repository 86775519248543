import React, { Fragment } from "react";
//router
import { Outlet } from "react-router-dom";
//components
import Navbar from "../components/Navbar/NavbarComponent";
import Footer from "../components/Footer/Footer";
const Layout = () => {
  return (
    <Fragment>
      <Navbar />
      <Outlet />
      <Footer />
    </Fragment>
  );
};

export default Layout;
