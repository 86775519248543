import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//css
import "./ServiceCard.css";
const ServiceCard = ({ id, card_h, card_p, card_img }) => {
  const navigate = useNavigate();
  const [docDir, setDir] = useState(document.querySelector("html").dir);
  useEffect(() => {
    setDir(document.querySelector("html").dir);
  }, [t]);
  console.log(docDir);
  // ======== handle go to service page==========
  const handleGoToService = () => {
    navigate(`/services/${id}`);
  };

  return (
    <div
      className="service-card background-set"
      style={{
        backgroundImage: `url(https://www.api.attargroup-co.com/image/${
          card_img && card_img
        })`,
      }}
      onClick={() => handleGoToService()}
    >
      <div
        className="service-card-info"
        style={{
          alignItems: docDir === "rtl" ? "end" : "flex-start",
        }}
      >
        <span className="p-title">{card_h}</span>
        <span>{card_p.slice(0, 40)}...</span>
      </div>
    </div>
  );
};

export default ServiceCard;
