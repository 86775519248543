import React, { useEffect, useState } from "react";
//images
import experianceImg from "../../assets/Img/expeianceImg.png";
import BannerImg from "../../assets/Img/Banner.png";
//icons
import { MdPeopleAlt } from "react-icons/md";
import { BsHeart } from "react-icons/bs";
import { AiFillLike } from "react-icons/ai";
//bootstrap
import { Container } from "react-bootstrap";
//components
import Banner from "../../components/Banner/Banner";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import ReactPlayer from "react-player/lazy";
import Button from "../../components/Button/Button";
//css
import "./Home.css";
//slider
import Slider from "react-slick";
import { settings } from "../../SliderSettings";
//tranlation
import { useTranslation } from "react-i18next";
//api
import axios from "../../api/axios";
const Home = () => {
  const [services, setServices] = useState([]);
  const { t } = useTranslation();
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  //==================Get Random SubServices===============
  const subServices = [];
  const getMultipleRandom = (arr, num) => {
    for (let i = 0; i < services.length; i++) {
      const element = services[i];
      if (element?.Subservices.length > 0) {
        subServices.push(...element.Subservices);
      }
    }
    const shuffled = [...subServices].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };
  const RandArr = getMultipleRandom(subServices, 4);
  //===============Get All Services=====================
  useEffect(() => {
    const getAllServices = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/service/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setServices([]);
      } else {
        setServices(body?.rows);
      }
    };
    getAllServices();
  }, []);
  return (
    <section className="home">
      <Banner
        h_text={t("Pages.Home.bannerTitle")}
        p_text={t(`Pages.Home.bannerDesc`)}
        buttonClass={"main-btn"}
        backgroundImg={BannerImg}
      />
      <Container>
        <div className="home-services">
          <div className="title">
            <h1>{t("Pages.Home.home-services.title")}</h1>
            <p>{t("Pages.Home.home-services.desc")}</p>
          </div>
          <div className="home-services-cards-responsive">
            <div>
              <Slider {...settings}>
                {services.length > 0 &&
                  services?.map((service, index) => {
                    return (
                      <div className="slider-div">
                        <ServiceCard
                          key={index}
                          id={service.id}
                          card_h={
                            docLang === "en"
                              ? service.title_en
                              : service.title_ar
                          }
                          card_p={
                            docLang === "en"
                              ? service.description_en
                              : service.description_ar
                          }
                          card_img={service.image}
                        />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className="home-experiance">
          <div className="home-experiance-img">
            <img src={experianceImg} alt="experianceImg" />
          </div>
          <div className="home-experiance-info">
            <h2>{t("Pages.Home.home-experiance.title")}</h2>
            <p>{t("Pages.Home.home-experiance.desc")}</p>
            <Button
              text={t("Pages.Home.home-experiance.btn")}
              classNames={"main-btn"}
            />
            <div className="home-experiance-info-stats">
              <div>
                <span className="stat-number">18Y+</span>
                <span>{t("Pages.Home.home-experiance.experiance")}</span>
              </div>
              <div>
                <span className="stat-number">42K+</span>
                <span>{t("Pages.Home.home-experiance.success-buisness")}</span>
              </div>
              <div>
                <span className="stat-number">980K+</span>
                <span>{t("Pages.Home.home-experiance.customers")}</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className="home-whyus">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            loop
            controls
            volume={50}
            pip
            id="myVideo"
          />
          <div className="home-whyus-content">
            <div>
              <div className="home-whyus-title">
                <h3>{t("Pages.Home.home-whyus.title")}</h3>
                <p>{t("Pages.Home.home-whyus.desc")}</p>
              </div>
              <div className="home-whyus-items">
                <div className="whyus-item">
                  <div>
                    <MdPeopleAlt />
                    <p className="p-title">
                      {t("Pages.Home.home-whyus.reaseon1.title")}
                    </p>
                  </div>
                  <p>{t("Pages.Home.home-whyus.reaseon1.desc")}</p>
                </div>
                <div className="whyus-item">
                  <div>
                    <BsHeart />
                    <p className="p-title">
                      {t("Pages.Home.home-whyus.reason2.title")}
                    </p>
                  </div>
                  <p>{t("Pages.Home.home-whyus.reason2.desc")}</p>
                </div>
                <div className="whyus-item">
                  <div>
                    <AiFillLike />
                    <p className="p-title">
                      {t("Pages.Home.home-whyus.reason3.title")}
                    </p>
                  </div>
                  <p>{t("Pages.Home.home-whyus.reason3.desc")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className="home-services">
          <div className="title">
            <h1>{t("Pages.Home.home-services.title")}</h1>
            <p>{t("Pages.Home.home-services.desc")}</p>
          </div>

          <div className="home-services-cards-responsive">
            <div>
              <Slider {...settings}>
                {RandArr.length > 0 &&
                  RandArr?.map((subService, index) => {
                    return (
                      <div className="slider-div">
                        <ServiceCard
                          key={index}
                          id={subService.id}
                          card_h={
                            docLang === "en"
                              ? subService.title_en
                              : subService.title_ar
                          }
                          card_p={
                            docLang === "en"
                              ? subService.description_en
                              : subService.description_ar
                          }
                          card_img={subService.image}
                        />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Home;
