import React, { useEffect, useState } from "react";
//css
import "./Services.css";
//components
import Banner from "../../components/Banner/Banner";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
//images
import ServicesBannerImg from "../../assets/Img/servicesBanner.png";
//bootstrap
import { Container } from "react-bootstrap";

//translation
import { useTranslation } from "react-i18next";
const Services = () => {
  const [services, setServices] = useState([]);
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  //===============Get All Services=====================
  useEffect(() => {
    const getAllServices = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/service/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setServices([]);
      } else {
        setServices(body?.rows);
      }
    };
    getAllServices();
  }, []);
  return (
    <section className="services">
      <Banner
        h_text={t("Pages.Services.bannerTitle")}
        p_text={t("Pages.Services.bannerDesc")}
        buttonClass={"secondary-btn"}
        backgroundImg={ServicesBannerImg}
      />
      <div className="services-container">
        <Container>
          <div className="services-wrapper">
            {services.length > 0 &&
              services?.map((service, index) => {
                return (
                  <div className="service-wrapper" key={index}>
                    <div className="services-p1">
                      <div className="title">
                        <h1>
                          {docLang === "en"
                            ? service.title_en
                            : service.title_ar}
                        </h1>
                        <p>
                          {docLang === "en"
                            ? service.description_en
                            : service.description_ar}
                        </p>
                      </div>
                    </div>
                    <div className="services-p1-cards">
                      {service?.Subservices.length > 0 &&
                        service?.Subservices.map((subservice, index) => {
                          return (
                            <ServiceCard
                              key={index}
                              id={subservice.id}
                              card_h={
                                docLang === "en"
                                  ? subservice.title_en
                                  : subservice.title_ar
                              }
                              card_p={
                                docLang === "en"
                                  ? subservice.description_en
                                  : subservice.description_ar
                              }
                              card_img={subservice.image}
                            />
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Services;
