import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//bootstrap
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
//data table
import DataTable from "react-data-table-component";
import { customStyles } from "../../components/DataTableCustomeStyles";
//components
import PopUp from "../../components/PopUp/PopUp";
//context
import AuthContext from "../../context/AuthProvider";
//css
import "./SubServices.css";
const SubServices = () => {
  const { auth } = useContext(AuthContext);
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [services, setServices] = useState("");
  const [subServices, setSubServices] = useState([]);
  const [subServiceId, setSubServiceId] = useState("");
  const [subService, setSubService] = useState({
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
    service_id: "",
  });
  //=======delete modal State====
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  const handleSubService = (e) => {
    const { name, value } = e.target;
    setSubService({ ...subService, [name]: value });
  };

  //====================Get All Services========================
  useEffect(() => {
    const getAllServices = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/service/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setServices([]);
        console.log(response);
      } else {
        setServices(body.rows);
      }
    };
    getAllServices();
  }, []);
  //=============================================================

  const handleSubmit = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    var formdata = new FormData();
    formdata.append("title_en", subService.title_en);
    formdata.append("title_ar", subService.title_ar);
    subService.description_en &&
      formdata.append("description_en", subService.description_en);
    subService.description_ar &&
      formdata.append("description_ar", subService.description_ar);
    formdata.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);
    formdata.append("service_id", subService.service_id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    for (const pair of formdata.entries()) {
      console.log(`${pair[0]}, ${pair[1]}`);
    }
    const response = await fetch(
      "https://www.api.attargroup-co.com/subservice/",
      requestOptions
    );
    if (!response.ok) {
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      window.location.reload();
    }
  };
  //================Get All Sub Services=========================
  const getAllSubServicesForService = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (subService.service_id) {
      const response = await fetch(
        `https://www.api.attargroup-co.com/subservice/?service=${subService.service_id}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(response);
        setSubServices([]);
      } else {
        setSubServices(body.rows);
      }
    }
  }, [subService.service_id]);
  useEffect(() => {
    getAllSubServicesForService();
  }, [getAllSubServicesForService]);

  //===================Delete Sub-Service========================
  const DeleteSubService = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/subservice/${subServiceId}`,
      requestOptions
    );
    if (!response.ok) {
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      window.location.reload();
    }
  };
  console.log(subServices);
  //=============================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <div>{index}</div>,
    },
    {
      name: "English Title",
      selector: (row) => <div>{row.title_en}</div>,
    },
    {
      name: "Arabic Title",
      selector: (row) => <div>{row.title_ar}</div>,
    },
    {
      name: "English Desc",
      selector: (row) => <div>{row.description_en}</div>,
    },
    {
      name: "Arabic Desc",
      selector: (row) => <div>{row.description_ar}</div>,
    },
    {
      name: "Image",
      selector: (row) => (
        <div>
          <img
            src={`https://www.api.attargroup-co.com/image/${row.image}`}
            alt=""
            width="100px"
          />
        </div>
      ),
    },
    {
      name: "Created At",
      selector: (row) => <div>{row.created_at}</div>,
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/sub-services/${row.id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setSubServiceId(row.id);
            }}
          >
            Delete Sub-Service
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Sub-Service</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure You Want To Delete This Sub-Service !! ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={DeleteSubService}>
                Delete Sub-Service
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  return (
    <section className="dashboard-sub-services">
      <Container>
        <Row>
          <div className="title">
            <h2>Sub - Services</h2>
            <p>Here Were You Can Modify Sub Services</p>
          </div>
        </Row>
        <Row>
          <div className="dashboard-services-form">
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Choose Service Parent</Form.Label>
                <Form.Select
                  name="service_id"
                  onChange={(e) => handleSubService(e)}
                >
                  <option>Choose Parent Service</option>
                  {services.length > 0 &&
                    services?.map((service, index) => (
                      <option key={index} value={service.id}>
                        {service.title_en}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group className="mb-3">
                <Form.Label>Title In English</Form.Label>
                <Form.Control
                  type="text"
                  name="title_en"
                  placeholder="Enter Title In English"
                  required
                  onChange={(e) => handleSubService(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Title In Arabic</Form.Label>
                <Form.Control
                  type="text"
                  name="title_ar"
                  placeholder="Enter Title In Arabic"
                  required
                  onChange={(e) => handleSubService(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description In English</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description_en"
                  rows={3}
                  placeholder="Description In English"
                  onChange={(e) => handleSubService(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description In Arabic</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description_ar"
                  rows={3}
                  placeholder="Description In Arabic"
                  onChange={(e) => handleSubService(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Upload Sub - Service Image</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={handleImageChange}
                />
              </Form.Group>

              <Button variant="success" type="submit">
                Create Sub - Service
              </Button>
            </Form>
          </div>
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <h4>Please Choose A Parent Service To Display It's Sub-Services</h4>
          <DataTable
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderWrap
            columns={columns}
            data={subService.service_id && subServices}
            customStyles={customStyles}
          />
        </Row>
      </Container>
    </section>
  );
};

export default SubServices;
