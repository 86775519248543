import React from "react";
//css
import "./ServiceContainer.css";
//bootstrap
import { Container } from "react-bootstrap";
const ServiceContainer = ({
  index,
  image,
  dir,
  h_text,
  p_text,
  graphical_img,
  background_color,
  PartOfServicesIndex,
}) => {
  return (
    <article className={`service-container`} dir={dir}>
      <div
        style={{
          background: `${background_color}`,
        }}
      >
        {graphical_img && (
          <img src={graphical_img} alt="GraphicalImg" id="GraphicalImg" />
        )}
        {PartOfServicesIndex % 2 === 0 && index === 0 && (
          <div className="service-blur">{/*  */}</div>
        )}
        <Container style={{ height: "100%" }}>
          <div className="service-info">
            <div className="service-info-img">
              <div
                className="img-bg"
                style={{
                  background:
                    PartOfServicesIndex % 2 === 0 ? " #2B2924" : "#DAE7A6",
                }}
              >
                <img
                  src={`https://www.api.attargroup-co.com/image/${image}`}
                  alt="service_img"
                  style={{
                    left: index === 0 ? "58px" : "-58px",
                  }}
                />
              </div>
            </div>
            <div className="service-info-content">
              <h3
                style={{
                  color:
                    PartOfServicesIndex % 2 === 0
                      ? "var(--main-white)"
                      : "#191814",
                }}
              >
                {h_text}
              </h3>
              <p
                style={{
                  color:
                    PartOfServicesIndex % 2 === 0
                      ? "var(--main-white)"
                      : "#191814",
                }}
              >
                {p_text}
              </p>
            </div>
          </div>
        </Container>
      </div>
    </article>
  );
};

export default ServiceContainer;
