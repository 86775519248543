import React from "react";
//css
import "./Button.css";
const Button = ({ icon, text, classNames }) => {
  return (
    <button className={`${classNames} banner-btn`}>
      {icon && <span>{icon}</span>}
      {text}
    </button>
  );
};

export default Button;
