import React from "react";
import { Link } from "react-router-dom";
//css
import "./Error.css";
//images
import ERRORIMG from "../../assets/Img/404.png";
//bootstrap
import { Button, Container, Row } from "react-bootstrap";
//translation
import { t } from "i18next";
const Error = () => {
  return (
    <section className="error-page">
      <div className="blur-circle"></div>
      <Container>
        <Row>
          <div className="error-page-content">
            <img src={ERRORIMG} alt="ERRORIMG" />
            <h4>{t("Pages.ErrorPage.desc")}</h4>
            <Link to={"/"}>
              <Button className="main-btn">{t("Pages.ErrorPage.btn")}</Button>
            </Link>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Error;
