import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import { languages } from "./languages";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/EN/translation.json";
import translationAr from "./locales/AR/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
//=============================Translation Options============================================
const resources = {
  en: {
    translation: translationEn,
  },
  ar: {
    translation: translationAr,
  },
};

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    react: {
      useSuspense: false,
      wait: true,
    },
    supportedLngs: ["en", "ar"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
    debug: true,
    whitelist: languages,
    nsSeperator: false,
    keySeperator: false,
    backend: {
      loadPath: "../src/locales/{{lng}}/translation.json",
      crossDomain: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
