import React, { useCallback, useContext, useEffect, useState } from "react";
//context
import AuthContext from "../../context/AuthProvider";
//bootstrap
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
//css
import "./Users.css";
//data table
import DataTable from "react-data-table-component";
//axios
import axios from "../../components/api/axios";
import PopUp from "../../components/PopUp/PopUp";
import { customStyles } from "../../components/DataTableCustomeStyles";
import { Link } from "react-router-dom";
const REGISTER_PATH = "/user/register";
const Users = () => {
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const { auth } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [Roles, setRoles] = useState([]);
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    role: "",
  });
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleUser = useCallback(
    (e) => {
      const { name, value } = e.target;
      setUser({ ...user, [name]: value });
    },
    [user]
  );
  //=======delete modal State====
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  //==========================Post Users===========================
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var data = JSON.stringify({
        name: user.name,
        phone: user.phone,
        email: user.email,
        password: user.password,
        tag: user.role === "super-admin" ? "1" : "2",
        role: user.role,
      });

      var config = {
        method: "post",
        url: `${REGISTER_PATH}`,
        headers: {
          Authorization: `bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .then(() => setSuccess(true))
        .then(() => setModalShow(true))
        .then(() => window.location.reload())
        .catch(function (error) {
          console.log(error);
          setSuccess(false);
          setModalShow(true);
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
  //==========================Fetch Users===========================
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        var config = {
          method: "get",
          url: "https://www.api.attargroup-co.com/user",
          headers: {
            Authorization: `bearer ${auth.token}`,
          },
        };

        await axios(config)
          .then(function (response) {
            setUsers(response?.data?.rows);
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };
    fetchUsers();
  }, [auth.token]);

  //==========================Fetch Roles===========================
  useEffect(() => {
    const fetchRoles = async () => {
      var config = {
        method: "get",
        url: "https://www.api.attargroup-co.com/role",
        headers: {
          Authorization: `bearer ${auth.token}`,
        },
      };

      await axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setRoles(response?.data?.rows);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchRoles();
  }, [auth.token]);
  //============================================
  const DeleteUser = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.attargroup-co.com/user/4",
      requestOptions
    );
    if (!response.ok) {
      alert(response.statusText);
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      window.location.reload();
    }
  };
  //============================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Name",
      selector: (row) => <span>{row?.name}</span>,
    },
    {
      name: "Email",
      selector: (row) => <span>{row?.email}</span>,
    },
    {
      name: "Phone",
      selector: (row) => <span>{row?.phone}</span>,
    },
    {
      name: "Status",
      selector: (row) => <span>{row?.status}</span>,
    },
    {
      name: "Role Name",
      selector: (row) => (
        <div>
          {row?.Roles?.map((role) => (
            <span>{role.role_name}</span>
          ))}
        </div>
      ),
    },
    {
      name: "Role Desc",
      selector: (row) => (
        <div>
          {row?.Roles?.map((role) => (
            <span>{role.role_desc}</span>
          ))}
        </div>
      ),
    },
    {
      name: "Resources",
      selector: (row) => (
        <div>
          {row?.Roles?.map((role) => {
            return role?.Resources?.map((item) => (
              <div>{item.resource_name}</div>
            ));
          })}
        </div>
      ),
    },
    {
      name: "Tag",
      selector: (row) => <span>{row?.tag}</span>,
    },
    {
      name: "Created At",
      selector: (row) => <span>{row?.created_at}</span>,
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/users/${row.id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
            }}
          >
            Delete User
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure You Want To Delete This User !! ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={DeleteUser}>
                Delete user
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  return (
    <section className="dashboard-users">
      <Container>
        <Row>
          <div className="title">
            <h1>Users</h1>
            <p>Here Were You Can Manipulate Users</p>
          </div>
        </Row>
        <Row>
          <div className="dashboard-users-form">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Enter User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={(e) => handleUser(e)}
                  placeholder="Enter User Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={(e) => handleUser(e)}
                  placeholder="Enter email"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Phone Number / <small>Format: 123-456-7890</small>
                </Form.Label>
                <Form.Control
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={(e) => handleUser(e)}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  required
                  placeholder="Enter Phone Number"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Choose New User Role</Form.Label>

                <Form.Select
                  name="role"
                  onChange={(e) => handleUser(e)}
                  required
                >
                  <option>Choose Role</option>
                  {Roles?.map((role, index) => {
                    return (
                      <option key={index} value={role.role_name}>
                        {role.role_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={(e) => handleUser(e)}
                  placeholder="Enter Password"
                  required
                />
              </Form.Group>

              <Button variant="success" type="submit">
                Create User
              </Button>
            </Form>
          </div>
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <DataTable
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderWrap
            columns={columns}
            data={users}
            customStyles={customStyles}
          />
        </Row>
      </Container>
    </section>
  );
};

export default Users;
