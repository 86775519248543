import React from "react";
//css
import "./ContactCard.css";
//icons
import { FaEnvelope } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
//translation
import { t } from "i18next";

const ContactCard = () => {
  return (
    <article className="contact-card">
      <h3>{t("Components.ContactCard.title")}</h3>
      <p>{t("Components.ContactCard.desc")}</p>
      <div className="contact-cards-icons">
        <div className="contact-card-icon">
          <span>
            <IoCall />
          </span>

          <span dir="ltr">+972 79 555 1502 / +962 64 393 643</span>
        </div>
        <div className="contact-card-icon">
          <span>
            <FaEnvelope />
          </span>
          <a href="mailto:muhannadattar@hubjo.co">muhannadattar@hubjo.co</a>
        </div>
      </div>
    </article>
  );
};

export default ContactCard;
