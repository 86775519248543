import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//css
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
//font
import "./fonts/TT.Octosquares/TTOctosquares-Regular.ttf";
//router
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./dashboard/context/AuthProvider";
//translation
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import "./i18next";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}></I18nextProvider>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
