import React, { useContext, useEffect, useState } from "react";
//bootstrap
import { Button, ButtonGroup, Container, Modal, Row } from "react-bootstrap";
//css
import "./Sessions.css";
//context
import AuthContext from "../../context/AuthProvider";
//data table
import DataTable from "react-data-table-component";
import { customStyles } from "../../components/DataTableCustomeStyles";
import PopUp from "../../components/PopUp/PopUp";
const Sesssions = () => {
  const { auth } = useContext(AuthContext);
  const [sessions, setSessions] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //=======delete modal State====
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  //=======delete modal State====
  const [DeleteModalShow2, setDeleteModalShow2] = useState(false);
  const handleDeleteModalClose2 = () => setDeleteModalShow2(false);
  const handleDeleteModalShow2 = () => setDeleteModalShow2(true);
  //=======delete modal State====
  const [DeleteModalShow3, setDeleteModalShow3] = useState(false);
  const handleDeleteModalClose3 = () => setDeleteModalShow3(false);
  const handleDeleteModalShow3 = () => setDeleteModalShow3(true);
  //========================get Active Sessions===================
  useEffect(() => {
    const getActiveSessions = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/session/show",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setSessions([]);
        console.log(body);
      } else {
        setSessions(body);
      }
    };
    getActiveSessions();
  }, []);
  //==================================================================
  const DestroySessionById = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/session/${sessionId}`,
      requestOptions
    );
    if (!response.ok) {
      alert(response.statusText);
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(4000);
      window.location.reload();
    }
  };
  //==================================================================
  const DestroyAllActiveSessionsForUserExeceptCurrent = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.attargroup-co.com/session/not-current",
      requestOptions
    );
    if (!response.ok) {
      alert(response.statusText);
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(4000);
      window.location.reload();
    }
  };
  //==================================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <div>{index}</div>,
    },
    {
      name: "IP Address",
      selector: (row) => <div>{row.ip_address}</div>,
    },
    {
      name: "Device",
      selector: (row) => <div>{row.device}</div>,
    },
    {
      name: "Logged In At",
      selector: (row) => <div>{row.logged_in_at}</div>,
    },
    {
      name: "Created At",
      selector: (row) => <div>{row.created_at}</div>,
    },
    {
      name: "Destroy Session",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setSessionId(row.id);
            }}
          >
            Destroy Session
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Destroy Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure You Want To Destroy This Session !! ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={DestroySessionById}>
                Destroy Session
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  return (
    <section className="dashboard-sessions">
      <Container>
        <Row>
          <div className="title">
            <h1>Sessions</h1>
            <p>Here Were You Can Manipulate Users Sessions</p>
          </div>
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
        <Row>
          <ButtonGroup style={{ gap: "10px" }}>
            <div>
              <Button
                variant="danger"
                onClick={() => {
                  handleDeleteModalShow2();
                }}
              >
                Destroy All Active Sessions For User Execept Current
              </Button>
              <Modal show={DeleteModalShow2} onHide={handleDeleteModalClose2}>
                <Modal.Header closeButton>
                  <Modal.Title>Destroy Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are You Sure You Want To Destroy All Active Sessions For User
                  Execept Current !! ?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleDeleteModalClose2}>
                    Close
                  </Button>
                  <Button
                    variant="danger"
                    onClick={DestroyAllActiveSessionsForUserExeceptCurrent}
                  >
                    Destroy Session
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div>
              <Button
                variant="danger"
                onClick={() => {
                  handleDeleteModalShow3();
                }}
              >
                Destroy Destroy All Active Sessions for User
              </Button>
              <Modal show={DeleteModalShow3} onHide={handleDeleteModalClose3}>
                <Modal.Header closeButton>
                  <Modal.Title>Destroy Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are You Sure You Want To Destroy All Active Sessions for User
                  !! ?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleDeleteModalClose3}>
                    Close
                  </Button>
                  <Button
                    variant="danger"
                    onClick={DestroyAllActiveSessionsForUserExeceptCurrent}
                  >
                    Destroy Session
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </ButtonGroup>
        </Row>
        <hr />
        <Row>
          <DataTable
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderWrap
            columns={columns}
            data={sessions}
            customStyles={customStyles}
          />
        </Row>
      </Container>
    </section>
  );
};

export default Sesssions;
