import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Container, Form, Row } from "react-bootstrap";
//css
import "./UpdateUser.css";
//context
import AuthContext from "../../../context/AuthProvider";
//components
import PopUp from "../../../components/PopUp/PopUp";
const UpdateUser = () => {
  const params = useParams();
  const { auth } = useContext(AuthContext);
  const userId = params.id;
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState({});
  const handleUser = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //========================================

  useEffect(() => {
    const getUserById = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.attargroup-co.com/user/${userId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setUser({});
      } else {
        setUser(body);
      }
    };
    getUserById();
  }, [userId]);
  //========================================

  const handleSubmit = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: user.name,
      phone: user.phone,
      email: user.email,
      status: user.status,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/user/${userId}`,
      requestOptions
    );
    if (!response.ok) {
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      navigate("/dashboard/users");
    }
  };
  return (
    <section className="update-user">
      <Container>
        <Row>
          <div className="title">
            <h1>Users</h1>
            <p>Here Were You Can Update User</p>
          </div>
        </Row>
        <Row>
          <div className="dashboard-users-form">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Enter User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={user.name}
                  onChange={(e) => handleUser(e)}
                  placeholder="Enter User Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={(e) => handleUser(e)}
                  placeholder="Enter email"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  id="phone"
                  name="phone"
                  value={user.phone}
                  onChange={(e) => handleUser(e)}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  required
                  placeholder="Enter Phone Number"
                />
                <small>Format: 123-456-7890</small>
              </Form.Group>
              <Form.Group>
                <Form.Label>User Status</Form.Label>
                <Form.Select
                  name="status"
                  value={user.status}
                  onChange={(e) => handleUser(e)}
                  required
                >
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                  <option value="suspended">Suspended</option>
                </Form.Select>
              </Form.Group>
              <br />
              <Button variant="success" type="submit">
                Update User
              </Button>
            </Form>
          </div>
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
      </Container>
    </section>
  );
};

export default UpdateUser;
