export const customStyles = {
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      width: "200px",
    },
  },
  rows: {
    style: {
      background: "#f7f7f7",
    },
  },
};
