import React, { useContext, useEffect, useState } from "react";
//context
import AuthContext from "../../context/AuthProvider";
//bootstrap
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
//css
import "./Services.css";
//components
import PopUp from "../../components/PopUp/PopUp";
//data table
import DataTable from "react-data-table-component";
import { customStyles } from "../../components/DataTableCustomeStyles";
import { Link } from "react-router-dom";
const Services = () => {
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const { auth } = useContext(AuthContext);
  const [services, setServices] = useState([]);
  const [service, setService] = useState({
    title: {
      t_en: "",
      t_ar: "",
    },
    desc: {
      d_en: "",
      d_ar: "",
    },
  });
  const [serviceId, setServiceId] = useState("");
  //=======delete modal State====
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  const handleServiceTitle = (e) => {
    const { name, value } = e.target;
    setService({
      ...service,
      title: { ...service.title, [name]: value },
    });
  };
  const handleServiceDesc = (e) => {
    const { name, value } = e.target;
    setService({
      ...service,
      desc: { ...service.desc, [name]: value },
    });
  };
  //================================Get All Services======================
  useEffect(() => {
    const getAllServices = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/service/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setServices([]);
        console.log(body);
      } else {
        setServices(body.rows);
      }
    };
    getAllServices();
  }, []);
  //================================Post Service==========================
  const handleSubmit = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    var formdata = new FormData();
    formdata.append("title_en", service.title.t_en);
    formdata.append("title_ar", service.title.t_ar);
    service.desc.d_en && formdata.append("description_en", service.desc.d_en);
    service.desc.d_ar && formdata.append("description_ar", service.desc.d_ar);
    formdata.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    for (var pair of formdata.entries()) {
      console.log(pair[0] + " - " + pair[1]);
    }

    const response = await fetch(
      "https://www.api.attargroup-co.com/service/",
      requestOptions
    );
    console.log(response);
    if (!response.ok) {
      setSuccess(false);
      setModalShow(true);
    } else {
      console.log(response);
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      window.location.reload();
    }
  };
  //=============================================================
  const DeleteService = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/service/${serviceId}`,
      requestOptions
    );
    if (!response.ok) {
      alert(response.statusText);
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      window.location.reload();
    }
  };
  //==============================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <div>{index}</div>,
    },
    {
      name: "English Title",
      selector: (row) => <div>{row.title_en}</div>,
    },
    {
      name: "Arabic Title",
      selector: (row) => <div>{row.title_ar}</div>,
    },
    {
      name: "English Desc",
      selector: (row) => <div>{row.description_en}</div>,
    },
    {
      name: "Arabic Desc",
      selector: (row) => <div>{row.description_ar}</div>,
    },
    {
      name: "Image",
      selector: (row) => (
        <div>
          <img
            src={`https://www.api.attargroup-co.com/image/${row.image}`}
            alt=""
            width="100px"
          />
        </div>
      ),
    },
    {
      name: "Created At",
      selector: (row) => <div>{row.created_at}</div>,
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/services/${row.id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setServiceId(row.id);
            }}
          >
            Delete Service
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Service</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure You Want To Delete This Service !! ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={DeleteService}>
                Delete Service
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  return (
    <section className="dashboard-services">
      <Container>
        <Row>
          <div className="title">
            <h2>Services</h2>
            <p>Here Were You Can Modify Services</p>
          </div>
        </Row>
        <Row>
          <div className="dashboard-services-form">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Title In English</Form.Label>
                <Form.Control
                  type="text"
                  name="t_en"
                  placeholder="Enter Title In English"
                  required
                  onChange={(e) => handleServiceTitle(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Title In Arabic</Form.Label>
                <Form.Control
                  type="text"
                  name="t_ar"
                  placeholder="Enter Title In Arabic"
                  required
                  onChange={(e) => handleServiceTitle(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description In English</Form.Label>
                <Form.Control
                  as="textarea"
                  name="d_en"
                  rows={3}
                  placeholder="Description In English"
                  onChange={(e) => handleServiceDesc(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description In Arabic</Form.Label>
                <Form.Control
                  as="textarea"
                  name="d_ar"
                  rows={3}
                  placeholder="Description In Arabic"
                  onChange={(e) => handleServiceDesc(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Upload Service Image</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={handleImageChange}
                />
              </Form.Group>
              <Button variant="success" type="submit">
                Create Service
              </Button>
            </Form>
          </div>
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <DataTable
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderWrap
            columns={columns}
            data={services}
            customStyles={customStyles}
          />
        </Row>
      </Container>
    </section>
  );
};

export default Services;
