import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//components
import Banner from "../Banner/Banner";
import ServiceContainer from "../ServiceContainer/ServiceContainer";
//css
import "./Service.css";
//images
import ServiceImg from "../../assets/Img/Service.png";
import graphicalImg from "../../assets/Img/graphic_elment03.png";
//translation
import { t } from "i18next";
const Service = () => {
  const params = useParams();
  const serviceId = params.id;
  const [service, setService] = useState({});
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  //=========================Get Service By Id=========================
  useEffect(() => {
    const getServiceById = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.attargroup-co.com/service/${serviceId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setService({});
        console.log(body);
      } else {
        setService(body);
      }
    };
    getServiceById();
  }, [serviceId]);
  return (
    <section className="service">
      <Banner
        h_text={t(docLang === "en" ? service.title_en : service.title_ar)}
        p_text={t(
          docLang === "en" ? service.description_en : service.description_ar
        )}
        backgroundImg={ServiceImg}
        buttonClass={"secondary-btn"}
      />
      {service?.Subservices?.reduce(
        (accumulator, currentValue, currentIndex, array) => {
          currentIndex % 2 === 0 &&
            accumulator.push(array.slice(currentIndex, currentIndex + 2));
          return accumulator;
        },
        []
      ).map((PartOfServices, PartOfServicesIndex) => {
        return (
          <div
            style={{
              background: PartOfServicesIndex % 2 === 0 ? "#191814" : "#f7f7f7",
            }}
            className={`partofservices`}
          >
            {PartOfServices.map((item, index) => {
              return (
                <ServiceContainer
                  index={index}
                  PartOfServicesIndex={PartOfServicesIndex}
                  dir={index === 0 ? "ltr" : "rtl"}
                  graphical_img={PartOfServicesIndex % 2 === 0 && graphicalImg}
                  h_text={docLang === "en" ? item.title_en : item.title_ar}
                  p_text={
                    docLang === "en" ? item.description_en : item.description_ar
                  }
                  image={item.image}
                />
              );
            })}
          </div>
        );
      })}
    </section>
  );
};

export default Service;
