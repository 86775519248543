import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../components/api/axios";
// import axios from "axios";
//css
import "./Login.css";
//Auth
import useAuth from "../../Hooks/UseAuth/useAuth";
const LOGIN_URL = "/user/login";
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  useEffect(() => {
    const result = EMAIL_REGEX.test(user);
    setUser(result);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var data = JSON.stringify({
        email: user,
        password: pwd,
      });

      var config = {
        method: "post",
        url: `${LOGIN_URL}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      if (response.status === 200) {
        const accessToken = response?.data?._token;
        setAuth({
          user: response?.data?.logged_in,
          password: pwd,
          token: accessToken,
        });
        localStorage.setItem("token", accessToken);
        localStorage.setItem("password", pwd);
        localStorage.setItem("user", response?.data?.logged_in);
        setUser("");
        setPwd("");
        navigate(from, { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response !!!");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <section className="login">
      <div className="login-form">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <h1>Sign In</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            // value={user}
            required
          />

          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            required
          />
          <button type="submit">Sign In</button>
        </form>
      </div>
    </section>
  );
};

export default Login;
