import React, { useContext, useEffect, useState } from "react";
//bootstrap
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { customStyles } from "../../components/DataTableCustomeStyles";
//components
import PopUp from "../../components/PopUp/PopUp";
//context
import AuthContext from "../../context/AuthProvider";
//css
import "./Permissions.css";
const Permissions = () => {
  const { auth } = useContext(AuthContext);
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [resources, setResources] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permissionsArr, setPermissionsArr] = useState([]);
  const [permissionId, setPermissionId] = useState("");
  const [showChecks, setShowChecks] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  //=======delete modal State====
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  //================Handle Permissions===============
  const handlePermissions = (e) => {
    const { name, value } = e.target;
    setPermissions({ ...permissions, [name]: value });
    setShowChecks(true);
  };
  //================Handle Permissions Checks===============
  const handlePermissionsChecks = (e) => {
    const { name, value } = e.target;
    if (e.target.checked) {
      setPermissions({ ...permissions, [name]: value === "on" && "yes" });
    } else {
      setPermissions({ ...permissions, [name]: value === "on" && "no" });
    }
  };
  //==============Get All Roles====================
  useEffect(() => {
    const getAllRoles = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/role",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setRoles([]);
        console.log(body);
      } else {
        setRoles(body.rows);
      }
    };
    getAllRoles();
  }, []);
  //=======================Get All Resources===================
  useEffect(() => {
    const getAllResources = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.attargroup-co.com/resource/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setResources([]);
        console.log(body);
      } else {
        setResources(body.rows);
      }
    };
    getAllResources();
  }, []);
  //===========================Get All Permissions ==============
  useEffect(() => {
    const getAllPermissions = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.attargroup-co.com/permission/role/${roleId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setPermissionsArr([]);
        console.log(response);
      } else {
        setPermissionsArr(body);
      }
    };
    getAllPermissions();
  }, [roleId]);
  //============================Post Permissions=================
  const PostPermissions = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(permissions);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.attargroup-co.com/permission/",
      requestOptions
    );
    if (!response.ok) {
      setSuccess(false);
      setModalShow(true);
    } else {
      console.log(response);
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      window.location.reload();
    }
  };
  //==================================Delete Permission ========
  const DeletePermission = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/permission/${permissionId}`,
      requestOptions
    );
    if (!response.ok) {
      alert(response.statusText);
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      window.location.reload();
    }
  };
  console.log(permissionsArr);
  //===========================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <div>{index}</div>,
    },
    {
      name: "Resource Name",
      selector: (row) => <div>{row.resource_name}</div>,
    },
    {
      name: "Resource Desc",
      selector: (row) => <div>{row.resource_desc}</div>,
    },
    {
      name: "Resource Role",
      selector: (row) => (
        <div>
          <p>{row.resource_name}</p>
          <ol>
            <li>Can Create :{row.Resource_Role.can_create}</li>
            <li>Can Delete :{row.Resource_Role.can_delete}</li>
            <li>Can Read :{row.Resource_Role.can_read}</li>
            <li>Can Update :{row.Resource_Role.can_update}</li>
          </ol>
        </div>
      ),
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/permissions/${row.id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setPermissionId(row.id);
              // setServiceId(row.id);
            }}
          >
            Delete Permission
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Permission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure You Want To Delete This Permission !! ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={DeletePermission}>
                Delete Permission
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  return (
    <section className="dashborad-permissions">
      <Container>
        <Row>
          <div className="title">
            <h2>Permissions</h2>
            <p>Here Were You Can Modify Permissions</p>
          </div>
        </Row>
        <Row>
          <Form>
            <Form.Group>
              <Form.Label>Select Role</Form.Label>
              <Form.Select
                name="role_id"
                onChange={(e) => {
                  handlePermissions(e);
                  setRoleId(e.target.value);
                }}
                required
              >
                <option>Please Select A Role</option>
                {roles.length > 0 &&
                  roles?.map((role, index) => {
                    return (
                      <option key={index} value={role.id}>
                        {role.role_name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <br />

            <Form.Group>
              <Form.Label>Select Resourece</Form.Label>
              <Form.Select
                name="resource_id"
                onChange={(e) => handlePermissions(e)}
                required
              >
                <option>Please Select A Resource</option>
                {resources.length > 0 &&
                  resources?.map((resource, index) => {
                    return (
                      <option key={index} value={resource.id}>
                        {resource.resource_name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Form>
        </Row>
        <br />
        <Row>
          {showChecks && (
            <div className="permissions-checks">
              <Form.Check
                type="switch"
                name="can_read"
                id="custom-switch"
                label="Can Create"
                onChange={(e) => handlePermissionsChecks(e)}
              />
              <Form.Check
                type="switch"
                name="can_create"
                id="custom-switch"
                label="Can Read"
                onChange={(e) => handlePermissionsChecks(e)}
              />
              <Form.Check
                type="switch"
                name="can_delete"
                id="custom-switch"
                label="Can Update"
                onChange={(e) => handlePermissionsChecks(e)}
              />
              <Form.Check
                type="switch"
                name="can_update"
                id="custom-switch"
                label="Can Delete"
                onChange={(e) => handlePermissionsChecks(e)}
              />
              <Button variant="success" onClick={PostPermissions}>
                Create Permission
              </Button>
            </div>
          )}
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
        <br />
        <Row>
          <DataTable
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderWrap
            columns={columns}
            data={roleId && permissionsArr.Resources}
            customStyles={customStyles}
          />
        </Row>
      </Container>
    </section>
  );
};

export default Permissions;
