import React, { useState, useContext, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Container, Form, Row } from "react-bootstrap";
//css
import "./UpdateServices.css";
//context
import AuthContext from "../../../context/AuthProvider";
//components
import PopUp from "../../../components/PopUp/PopUp";
const UpdateServices = () => {
  const params = useParams();
  const serviceId = params.id;
  const { auth } = useContext(AuthContext);
  const [service, setService] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  const handleService = (e) => {
    const { name, value } = e.target;
    setService({ ...service, [name]: value });
  };
  //===========================Get Service By Id=============
  const getServiceById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/service/${serviceId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setService({});
      console.log(body);
    } else {
      setService(body);
    }
  }, [serviceId]);
  useEffect(() => {
    getServiceById();
  }, [getServiceById]);
  //=====================Update Service Image================
  const UpdateServiceImage = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var formdata = new FormData();
    formdata.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/service/image/${serviceId}`,
      requestOptions
    );
    if (!response.ok) {
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      alert("Image Updated");
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      navigate("/dashboard/services");
    }
  };
  //===========================Update Service================
  const handleSubmit = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      title_en: service.title_en,
      title_ar: service.title_ar,
      description_en: service.description_en,
      description_ar: service.description_ar,
    };

    Object.keys(raw).forEach((key) => {
      if (
        raw[key] === "" ||
        raw[key] === "\n" ||
        raw[key] === undefined ||
        raw[key] === null
      ) {
        delete raw[key];
      }
    });
    let raw2 = JSON.stringify(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.attargroup-co.com/service/${serviceId}`,
      requestOptions
    );
    if (!response.ok) {
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      navigate("/dashboard/services");
    }
  };
  console.log(service);
  return (
    <section className="update-services">
      <Container>
        <Row>
          <div className="title">
            <h2>Services</h2>
            <p>Here Were You Can Update A Service</p>
          </div>
        </Row>
        <Row>
          <div className="dashboard-services-form">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Title In English</Form.Label>
                <Form.Control
                  type="text"
                  name="title_en"
                  placeholder="Enter Title In English"
                  value={service.title_en}
                  onChange={(e) => handleService(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Title In Arabic</Form.Label>
                <Form.Control
                  type="text"
                  name="title_ar"
                  placeholder="Enter Title In Arabic"
                  value={service.title_ar}
                  onChange={(e) => handleService(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description In English</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description_en"
                  rows={3}
                  placeholder="Description In English"
                  value={service.description_en}
                  onChange={(e) => handleService(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description In Arabic</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description_ar"
                  rows={3}
                  placeholder="Description In Arabic"
                  value={service.description_ar}
                  onChange={(e) => handleService(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Upload Service Image</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={handleImageChange}
                />
              </Form.Group>
              <Button variant="success" type="submit">
                Update Service
              </Button>
              <br />
              {Imgfile.Imgfile && (
                <Button
                  variant="info"
                  type="button"
                  onClick={UpdateServiceImage}
                >
                  Update Image
                </Button>
              )}
            </Form>
          </div>
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
      </Container>
    </section>
  );
};

export default UpdateServices;
