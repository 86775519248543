import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Container, Form, Row } from "react-bootstrap";
//components
import PopUp from "../../../components/PopUp/PopUp";
//context
import AuthContext from "../../../context/AuthProvider";
//css
import "./UpdatePermissions.css";
const UpdatePermissions = () => {
  const params = useParams();
  const permissionId = params.id;
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [permission, setPermission] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //================Handle Permissions Checks===============
  const handlePermissionsChecks = (e) => {
    const { name, value } = e.target;
    if (e.target.checked) {
      setPermission({ ...permission, [name]: value === "on" && "yes" });
    } else {
      setPermission({ ...permission, [name]: value === "on" && "no" });
    }
  };
  //=================Get Permission By Id ======================
  useEffect(() => {
    const getPermissionById = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.attargroup-co.com/permission/${permissionId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setPermission({});
        console.log(body);
      } else {
        setPermission({
          can_read: body.can_read,
          can_create: body.can_create,
          can_delete: body.can_delete,
          can_update: body.can_update,
        });
      }
    };
    getPermissionById();
  }, [permissionId]);

  //=========================Update Permission =============
  const UpdatePermission = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(permission);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/permission/${permissionId}`,
      requestOptions
    );
    if (!response.ok) {
      console.log(response);
      setSuccess(false);
      setModalShow(true);
    } else {
      setSuccess(true);
      setModalShow(true);
      await delay(3000);
      navigate("/dashboard/permissions");
    }
  };
  useEffect(() => {
    const checks = document.querySelectorAll("#custom-switch");
    for (let i = 0; i < checks.length; i++) {
      const check = checks[i];
      for (const property in permission) {
        if (check.name === property) {
          if (permission[property] === "yes") {
            check.checked = true;
          } else {
            check.checked = false;
          }
        }
      }
    }
  }, [permission]);
  return (
    <section className="update-permissions">
      <Container>
        <Row>
          <div className="title">
            <h2>Permissions</h2>
            <p>Here Were You Can Update Permission</p>
          </div>
        </Row>

        <Row>
          {
            <div className="permissions-checks">
              <Form.Check
                type="switch"
                name="can_read"
                id="custom-switch"
                label="Can Create"
                onChange={(e) => handlePermissionsChecks(e)}
              />
              <Form.Check
                type="switch"
                name="can_create"
                id="custom-switch"
                label="Can Read"
                onChange={(e) => handlePermissionsChecks(e)}
              />
              <Form.Check
                type="switch"
                name="can_delete"
                id="custom-switch"
                label="Can Update"
                onChange={(e) => handlePermissionsChecks(e)}
              />
              <Form.Check
                type="switch"
                name="can_update"
                id="custom-switch"
                label="Can Delete"
                onChange={(e) => handlePermissionsChecks(e)}
              />
              <Button variant="success" onClick={UpdatePermission}>
                Update Permission
              </Button>
            </div>
          }
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
      </Container>
    </section>
  );
};

export default UpdatePermissions;
