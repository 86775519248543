import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Container, Form, Row } from "react-bootstrap";
//css
import "./UpdateRole.css";
//context
import AuthContext from "../../../context/AuthProvider";
import PopUp from "../../../components/PopUp/PopUp";
const UpdateRole = () => {
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const params = useParams();
  const roleId = params.id;
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const [role, setRole] = useState({
    role_name: "",
    role_desc: "",
  });
  const handleRole = (e) => {
    const { name, value } = e.target;
    setRole({
      ...role,
      [name]: value,
    });
  };
  //============================get Role By Id =======================
  const getRoleById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${auth.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.attargroup-co.com/role/${roleId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setRole({});
      console.log(body);
    } else {
      setRole({
        ...role,
        role_name: body.role_name,
        role_desc: body.role_desc,
      });
    }
  }, [roleId]);
  useEffect(() => {
    getRoleById();
  }, [getRoleById]);
  //==================================Update Role========================
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${auth.token}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        role_name: role.role_name,
        role_desc: role.role_desc,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.attargroup-co.com/role/${roleId}`,
        requestOptions
      );
      if (!response.ok) {
        console.log(response);
        setSuccess(false);
        setModalShow(true);
      } else {
        setSuccess(true);
        setModalShow(true);
        await delay(4000);
        navigate("/dashboard/roles");
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
  console.log(role);
  return (
    <section className="update-role">
      <Container>
        <Row>
          <div className="title">
            <h1>Roles</h1>
            <p>Here Were You Can Update Role</p>
          </div>
        </Row>
        <Row>
          <div className="dashboard-roles">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  name="role_name"
                  value={role.role_name}
                  onChange={(e) => handleRole(e)}
                  placeholder="Enter Role Name"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Role Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  resize="false"
                  placeholder="Description In Arabic"
                  name="role_desc"
                  value={role.role_desc}
                  onChange={(e) => handleRole(e)}
                />
              </Form.Group>

              <Button variant="success" type="submit">
                Update Role
              </Button>
            </Form>
          </div>
          <PopUp
            modalShow={modalShow}
            setModalShow={setModalShow}
            success={success}
          />
        </Row>
      </Container>
    </section>
  );
};

export default UpdateRole;
