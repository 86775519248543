import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div>
      <h1>Error404 Not Found !!</h1>
      <Link to={"/dashboard"}>Back To Dashboard</Link>
    </div>
  );
};

export default Error404;
